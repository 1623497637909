.category-title{
    width:65vw;
    background-color: #a96e21;
    color: #e6d6b0;
    padding: 15px 20px 15px 20px;
    font-weight: bold;
    margin: 10px auto;

}

@media (max-device-width: 420px) {
    .category-title{
        width:80vw;

    }
}